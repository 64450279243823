import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { routes } from './app.routes';
import { apiInterceptorFn } from './interceptors/api.interceptor';
import { BlockchainProvidersModule } from '@listat/blockchain-providers';
import { PROVIDER_CONFIGS } from 'config';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideHttpClient(
            withFetch(),
            withInterceptors([
                apiInterceptorFn
            ])
        ),
        importProvidersFrom(
            BlockchainProvidersModule.forRoot(PROVIDER_CONFIGS)
        )
    ]
};
