import { ChainId, IProvidersConfig } from "@listat/blockchain-providers";

export const PROVIDER_CONFIGS: IProvidersConfig = {
    ALCHEMY_FRONTEND_URLS: {
        [ ChainId.ETHEREUM_MAINNET ]: [
            // "https://mainnet.infura.io/v3/7c6090b54334481c9bab5a787bd2d03f",
            // "https://mainnet.infura.io/v3/3a1e9577435745f0af6f7e462bd9ef39",
            // "https://go.getblock.io/d57382eae0a642858ca7ff5fccba4cb0",
            "https://ethereum-mainnet.core.chainstack.com/267a59981b2d7d73a9430e4833b82fe0",
            "https://rpc.ankr.com/eth",
            "https://cloudflare-eth.com",
            "https://eth.public-rpc.com",
            "https://rpc.flashbots.net",
        ],
        [ ChainId.ETHEREUM_GOERLI ]: [
            "https://eth-goerli.g.alchemy.com/v2/mYiO4yTkY6GqVl96FkM5uJnnEb68NhCz",
            "https://eth-goerli.g.alchemy.com/v2/45B406IBlDc3EIK0-pJZuD3bB3nIZFwK"
        ],
        [ ChainId.ETHEREUM_ROPSTEN ]: [
            "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
            "https://rpc.ankr.com/eth_ropsten"
        ],
        [ ChainId.ETHEREUM_SEPOLIA ]: [
            "https://1rpc.io/sepolia",
            "https://eth-sepolia.public.blastapi.io",
            "https://eth-sepolia-public.unifra.io",
            "https://sepolia.gateway.tenderly.co"
        ]
    },
    BSC_PROVIDER_URLS: {
        mainnet: [
            'https://bsc-dataseed.binance.org/',
            'https://bsc-dataseed1.defibit.io/',
            'https://bsc-dataseed1.ninicoin.io/',
            'https://bsc-dataseed1.binance.org/',
            'https://bsc-dataseed2.binance.org/',
            'https://bsc-dataseed3.binance.org/'
        ],
        testnet: [
            'https://data-seed-prebsc-1-s1.binance.org:8545/',
            // 'https://data-seed-prebsc-2-s1.binance.org:8545/',
            // 'https://data-seed-prebsc-1-s2.binance.org:8545/',
            'https://data-seed-prebsc-1-s3.binance.org:8545/',
            'https://data-seed-prebsc-2-s3.binance.org:8545/'
        ]
    },
    HARDHAT_PROVIDER_URLS: {
        testnet: [
            'http://127.0.0.1:8545'
        ]
    },
    FANTOM_PROVIDER_URLS: {},
    POLYGON_PROVIDER_URLS: {
        mainnet: [
            "https://rpc-mainnet.maticvigil.com",
            "https://polygon-mainnet.public.blastapi.io",
            "https://rpc-mainnet.matic.quiknode.pro",
            "https://rpc.ankr.com/polygon",
            "https://polygon-rpc.com",
            // "https://matic-mainnet-archive-rpc.bwarelabs.com"
        ],
        testnet: [
            // "https://public-rpc.blockpi.io/http/polygon-mumbai-testnet",
            // "https://rpc-mumbai.matic.today"
            "https://rpc.ankr.com/polygon_mumbai",
            'https://polygon-mumbai.g.alchemy.com/v2/CgSUwvdck3ob7FiGgVbkQfg8En_SdRZA',
            // "https://matic-mumbai.chainstacklabs.com"
        ]
    },
    WALLETCONNECT_RPC: {
        [ ChainId.ETHEREUM_MAINNET ]: `https://eth-mainnet.alchemyapi.io/v2/tyC39MlQKMjgqUmAy2y5ad05Z8bv-Ca9`,
        [ ChainId.ETHEREUM_ROPSTEN ]: `https://rpc.ankr.com/eth_ropsten`,
        [ ChainId.ETHEREUM_GOERLI ]: `https://rpc.ankr.com/eth_goerli`,
        [ ChainId.BSC_MAINNET ]: 'https://bsc-dataseed.binance.org/',
        [ ChainId.BSC_TESTNET ]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        [ ChainId.POLYGON_MAINNET ]: 'https://polygon-rpc.com/',
        [ ChainId.POLYGON_AMOY ]: 'https://rpc.ankr.com/polygon_mumbai',
        [ ChainId.FANTOM_MAINNET ]: 'https://rpc.ftm.tools',
        [ ChainId.FANTOM_TESTNET ]: 'https://rpc.testnet.fantom.network/',
    },
    ETHERSCAN_PROVIDER_ID: "S7PGJY9HI57KSMKZX3PZJEZBQ3EJMG85FR",
    SOLANA_LAMPORTS_IN_SOL: 1e9,
    SOLANA_RPC: {
        [ ChainId.SOLANA_MAINNET ]: "https://solana-api.projectserum.com"
    },
    BASE_PROVIDER_URLS: {
        mainnet: [
            "https://mainnet.base.org",
            "https://1rpc.io/base",
            "https://base-pokt.nodies.app"
        ]
    }
};

export const explorersApi: any = {
    1: 'https://api.etherscan.io',
    137: 'https://api.polygonscan.com',
    56: 'https://api.bscscan.com',
    97: 'https://api-testnet.bscscan.com',
    80001: 'https://api-testnet.polygonscan.com',
    [ ChainId.ETHEREUM_SEPOLIA ]: 'https://api-sepolia.etherscan.io'
};

export const apiKeys: any = {
    1: 'UJK4RGYHD17433JT8W6SJXMRACREC6B9J9',
    137: 'Y5FZ7BS5DKEUCS4UGF2EB1KD41QZMMQPVP',
    56: 'MPB7UDX6S6KN7WW6NRAQBQ9UVBSR5ABHMM',
    97: 'MPB7UDX6S6KN7WW6NRAQBQ9UVBSR5ABHMM',
    80001: 'MPB7UDX6S6KN7WW6NRAQBQ9UVBSR5ABHMM',
    [ ChainId.ETHEREUM_SEPOLIA ]: 'UJK4RGYHD17433JT8W6SJXMRACREC6B9J9'
};

export const BRIDGE = {
    [ ChainId.ETHEREUM_MAINNET ]: "0x99E2A03cd045F93533B0462f3873E272823D777E",
    [ ChainId.BSC_MAINNET ]: "0x99E2A03cd045F93533B0462f3873E272823D777E",
    [ ChainId.BSC_TESTNET ]: "",
    [ ChainId.POLYGON_AMOY ]: "0xc054f083a0ac7C3e33A4Dd6B10A58E71D80E990d",
    [ ChainId.POLYGON_MAINNET ]: "",
    [ ChainId.ETHEREUM_ROPSTEN ]: '',
    [ ChainId.ETHEREUM_GOERLI ]: '',
    [ ChainId.ETHEREUM_SEPOLIA ]: '0x42f317bE156B3fFb1A0fBdD3FE3b849f68e20Baf',
    [ ChainId.ETHEREUM_KOVAN ]: '',
    [ ChainId.SOLANA_MAINNET ]: '',
    [ ChainId.SOLANA_DEVNET ]: '',
    [ ChainId.SOLANA_TESTNET ]: '',
    [ ChainId.FANTOM_MAINNET ]: '',
    [ ChainId.FANTOM_TESTNET ]: '',
    [ ChainId.HARDHAT_TESTNET ]: '',
    [ ChainId.AVALANCHE_MAINNET ]: '',
    [ ChainId.AVALANCHE_TESTNET ]: '',
};
