import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";

export const apiInterceptorFn: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
) =>
{
    if (req.url.startsWith('/api'))
    {
        req = req.clone({
            url: '' + req.url,
            withCredentials: true
        });
    }

    return next(req);
};
